import React, { Component } from 'react';
import { motion } from 'framer-motion';
import { Link, animateScroll as scroll } from 'react-scroll';

class MobileMenu extends Component {
    state = {  }

    linkClickHandler = () => this.props.toggleMobileMenu();

    navLinksList = this.props.navLinks.map(link => {
        return (
            <motion.li 
                whileHover={{ y: -3 }}
                whileTap={{ y: 3 }}
                transition={{ duration: 0.3 }}
                key={link.text}
            >
                <Link 
                    activeClass="active"
                    to={link.url}
                    spy={true}
                    smooth={true}
                    offset={-64}
                    onClick={() => this.linkClickHandler()}
                >
                    {link.text}
                </Link>
            </motion.li>
        )
    })

    contactLinksList = this.props.contactLinks.map(link => {
        return (
            <motion.li 
                whileHover={{ y: -3 }}
                whileTap={{ y: 3 }}
                transition={{ duration: 0.3 }}
                key={link.text}
            >
                <a 
                    href={link.url}
                    onClick={() => this.linkClickHandler()}
                >
                    {link.text}
                </a>
            </motion.li>
        )
    })

    render() { 
        return (
            <motion.div 
                animate={this.props.mobileMenuOpen ? {x: 0} : {x: 800}}
                initial={{ x: 800 }}
                transition={{ duration: .5 }}
                className="MobileMenu"
            >
                <ul>{this.navLinksList}</ul>
                <div className="underline" />
                <ul>{this.contactLinksList}</ul>
            </motion.div>
        );
    }
}
 
export default MobileMenu;