import React, { Component } from 'react';
import { motion } from 'framer-motion';

class Hamburger extends Component {
    // state = { menuOpen: false }

    clickHandler = () => this.props.toggleMobileMenu();

    render() {

        // const variants = {
        //     topLine: {
        //         toX: {
        //             y: [8, 8],
        //             rotate: [0, 45]
        //         },
        //         toHam: {
        //             y: [8, 0],
        //             rotate: [0, 0]
        //         }
        //     },
        //     bottomLine: {
        //         toX: {
        //             y: [-8, -8],
        //             rotate: [0, -45]
        //         },
        //         toHam: {
        //             y: [-8, 0],
        //             rotate: [0, 0]
        //         }
        //     }
        // }

        return (
            <div className="Hamburger" onClick={() => this.clickHandler()} >
                <motion.div 
                    animate={this.props.mobileMenuOpen ? {y: 8, rotate: 45} : {y: 0, rotate: 0}}
                    // animate={this.state.menuOpen ? variants.topLine.toX : variants.topLine.toHam}
                    // transition={{ rotate: {delay: .2} }}
                    transition={this.props.mobileMenuOpen ? {rotate: {delay: .2}} : {y: {delay: .2}}}
                    // variants={variants}
                    className="line" 
                    id="top" 
                />
                <motion.div 
                    className={this.props.mobileMenuOpen ? 'line hide' : 'line'} 
                    id="middle" 
                />
                <motion.div 
                    animate={this.props.mobileMenuOpen ? {y: -8, rotate: -45} : {y: 0, rotate: 0}}
                    // animate={this.state.menuOpen ? variants.bottomLine.toX : variants.bottomLine.toHam}
                    // transition={{ rotate: {delay: .2} }}
                    transition={this.props.mobileMenuOpen ? {rotate: {delay: .2}} : {y: {delay: .2}}}
                    // variants={variants}
                    className="line" 
                    id="bottom" 
                />
            </div>
        );
    }
}
 
export default Hamburger;