import React, { Component } from 'react';
import Hamburger from '../Hamburger/Hamburger';
import { motion } from 'framer-motion';
import { Link, animateScroll as scroll } from 'react-scroll';

class Navbar extends Component {
    state = {  }

    logoClickHandler = () => {
        scroll.scrollToTop();

        if (this.props.mobileMenuOpen) {
            this.props.toggleMobileMenu();
        }
    }

    linksList = this.props.navLinks.map(link => {
        return (
            <motion.li 
                whileHover={{ y: -3 }}
                whileTap={{ y: 3 }}
                transition={{ duration: 0.3 }}
                key={link.text}
            >
                <Link 
                    activeClass="active"
                    to={link.url}
                    spy={true}
                    smooth={true}
                    offset={-64}
                >
                    {link.text}
                </Link>
            </motion.li>
        )
    })

    render() { 
        return (  
            <nav className="Navbar">
                <a onClick={() => this.logoClickHandler()}>
                    {/* {this.props.logo.img} */}
                    <img src={this.props.logo.img} alt='logo' />
                    <div>
                        <p>Michael Weber</p>
                        <p>Front End Developer</p>
                    </div>
                </a>
                <ul>{this.linksList}</ul>
                <Hamburger 
                    mobileMenuOpen={this.props.mobileMenuOpen}
                    toggleMobileMenu={this.props.toggleMobileMenu}
                />
            </nav>
        );
    }
}
 
export default Navbar;