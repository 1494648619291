import React, { Component } from 'react';
import { motion } from 'framer-motion';
import HeroImage from '../HeroImage/HeroImage';
import lightbulbs from '../../images/lightbulbs.png';

class Hero extends Component {
    state = {  }
    render() { 
        return (  
            <header className="Hero">
                <motion.div
                    animate={{ x: 0, opacity: 1 }}
                    initial={{ x: -200, opacity: 0 }}
                    transition={{ duration: 1 }}
                >
                    {/* <p>Michael Weber | Front End Developer</p> */}
                    <h1>Bridging the <span className="blue-text">creative</span> and the <span className="blue-text">technical</span> to bring ideas to life</h1>
                    {/* <motion.a 
                        whileHover={{ y: -3 }}
                        whileTap={{ y: 3 }}
                        href="mailto:mscottweber@gmail.com"
                    >
                        Let's create something together >
                    </motion.a> */}
                    <motion.a 
                        whileTap={{ y: 3 }}
                        className="primary-btn" 
                        href='mailto:mscottweber@gmail.com'
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Let's create something together
                    </motion.a>
                </motion.div>
                {/* <HeroImage /> */}
                <motion.img 
                    animate={{ x: 0, opacity: 1 }}
                    initial={{ x: 200, opacity: 0 }}
                    transition={{ duration: 1, delay: .5 }}
                    src={lightbulbs}
                    alt="lightbulbs sprouting out of each other"
                />
            </header>
        );
    }
}
 
export default Hero;